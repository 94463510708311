import _fakeTimers from "@sinonjs/fake-timers";
import _index from "./index";
var exports = {};
var FakeTimers = _fakeTimers;
var fakeServer = _index; // eslint-disable-next-line no-empty-function

function Server() {}

Server.prototype = fakeServer;
var fakeServerWithClock = new Server();

fakeServerWithClock.addRequest = function addRequest(xhr) {
  if (xhr.async) {
    if (typeof setTimeout.clock === "object") {
      this.clock = setTimeout.clock;
    } else {
      this.clock = FakeTimers.install();
      this.resetClock = true;
    }

    if (!this.longestTimeout) {
      var clockSetTimeout = this.clock.setTimeout;
      var clockSetInterval = this.clock.setInterval;
      var server = this;

      this.clock.setTimeout = function (fn, timeout) {
        server.longestTimeout = Math.max(timeout, server.longestTimeout || 0);
        return clockSetTimeout.apply(this, arguments);
      };

      this.clock.setInterval = function (fn, timeout) {
        server.longestTimeout = Math.max(timeout, server.longestTimeout || 0);
        return clockSetInterval.apply(this, arguments);
      };
    }
  }

  return fakeServer.addRequest.call(this, xhr);
};

fakeServerWithClock.respond = function respond() {
  var returnVal = fakeServer.respond.apply(this, arguments);

  if (this.clock) {
    this.clock.tick(this.longestTimeout || 0);
    this.longestTimeout = 0;

    if (this.resetClock) {
      this.clock.uninstall();
      this.resetClock = false;
    }
  }

  return returnVal;
};

fakeServerWithClock.restore = function restore() {
  if (this.clock) {
    this.clock.uninstall();
  }

  return fakeServer.restore.apply(this, arguments);
};

exports = fakeServerWithClock;
export default exports;