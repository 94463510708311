import _fakeServer from "./fake-server";
import _fakeServerWithClock from "./fake-server/fake-server-with-clock";
import _fakeXhr from "./fake-xhr";
var exports = {};
exports = {
  fakeServer: _fakeServer,
  fakeServerWithClock: _fakeServerWithClock,
  fakeXhr: _fakeXhr
};
export default exports;
export const fakeServer = exports.fakeServer;