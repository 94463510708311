import _util from "util";
var exports = {};
var inspect = _util.inspect;

function log(response, request) {
  var str;
  str = "Request:\n" + inspect(request) + "\n\n";
  str += "Response:\n" + inspect(response) + "\n\n";
  /* istanbul ignore else: when this.logger is not a function, it can't be called */

  if (typeof this.logger === "function") {
    this.logger(str);
  }
}

exports = log;
export default exports;